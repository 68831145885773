import { Flex, MenuProps } from 'antd';
import React from 'react';

import { ReactComponent as SignalFilled } from 'assets/icons/SignalFilled.svg';
import { ReactComponent as Bills } from 'assets/icons/Bills.svg';
import { ReactComponent as ElectricMeter } from 'assets/icons/ElectricMeter.svg';
import { ReactComponent as PhoneFilled } from 'assets/icons/PhoneFilled.svg';
import { ReactComponent as Request } from 'assets/icons/Request.svg';
import { ReactComponent as ThunderboltFilled } from 'assets/icons/ThunderboltFilled.svg';

export type MenuItem = Required<MenuProps>['items'][number];

export const navigationMenus = [
  {
    key: '/',
    label: 'Factures',
    icon: <Bills />,
  },
  {
    key: '/consommations',
    label: 'Consommations',
    icon: <ThunderboltFilled />,
  },
  {
    key: '/compteurs',
    label: 'Compteurs',
    icon: <ElectricMeter />,
  },
  {
    key: '/demandes',
    label: 'Demandes',
    icon: <Request />,
  },
  {
    key: '/contact',
    label: 'Contact',
    icon: <PhoneFilled />,
  },
];
const navigationConfig = (onClick?: (e: any) => void): MenuItem[] =>
  navigationMenus.map((menu) => {
    return {
      ...menu,
      onClick,
      icon: (
        <Flex style={{ width: 24 }} justify={'center'}>
          {menu.icon}
        </Flex>
      ),
    };
  });

export default navigationConfig;
