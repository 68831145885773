import React, { Fragment, lazy, Suspense, useEffect } from 'react';
import { AuthLayout, AppLayout } from 'layouts';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LoadingScreen } from 'components';
import { AuthGuard, AppGuard } from 'routes/guards';
import { RouteConfig } from 'react-router-config';
import { Userpilot } from 'userpilot';

interface RoutesConfig extends RouteConfig {
  layout?: React.ComponentType<any>;
  guard?: React.ComponentType<any>;
}

const routesConfig: RoutesConfig[] = [
  {
    path: '/errors/error-404',
    exact: true,
    component: lazy(() => import('views/errors/404.page')),
    layout: Fragment,
    guard: Fragment,
  },
  {
    path: '/',
    exact: true,
    component: lazy(() => import('views/billings/Billings.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/demandes',
    exact: true,
    component: lazy(() => import('views/requests/Requests.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/demandes/:id',
    exact: true,
    component: lazy(() => import('views/requests/Requests.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/profile',
    exact: true,
    component: lazy(() => import('views/user/UserProfile.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/compteurs',
    exact: true,
    component: lazy(() => import('views/compteur/Compteur.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/compteurs-:id',
    exact: true,
    component: lazy(() => import('views/compteur/CompteurDetails.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/consommations',
    exact: true,
    component: lazy(() => import('views/consommations/Consommations.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '/reinitialiser-mot-de-passe',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/creation-compte',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/connexion',
    exact: true,
    component: lazy(() => import('views/auth/Login.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/mot-de-passe-oublie',
    exact: true,
    component: lazy(() => import('views/auth/ResetPasswordInit.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/first-login',
    exact: true,
    component: lazy(() => import('views/auth/UpdatePassword.page')),
    layout: AuthLayout,
    guard: AuthGuard,
  },
  {
    path: '/contact',
    exact: true,
    component: lazy(() => import('views/contact/Contact.page')),
    layout: AppLayout,
    guard: AppGuard,
  },
  {
    path: '*',
    component: () => <Redirect to="/errors/error-404" />,
    layout: Fragment,
    guard: Fragment,
  },
];

const Routes = ({ routes = routesConfig }: { routes?: RouteConfig[] }) => {
  const location = useLocation();
  useEffect(() => {
    Userpilot.reload();
  }, [location.pathname]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map(({ guard, layout, component, path, exact, routes }) => {
          const Guard = guard || Fragment;
          const Layout = layout || Fragment;
          if (!component) return null;

          const Component = component;

          return (
            <Route
              key={path?.toString()}
              path={path}
              exact={exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    <Component {...props} route={routes} />
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Routes;
