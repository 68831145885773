export default {
  translation: {
    'shared.french': 'English',
    'app.name': 'Plateforme Énergie par Hellio',
    'auth.hero': 'Bienvenue\nsur la plateforme\nÉnergie par Hellio',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    close: 'Fermer',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères :\nune majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  reset_password: {
    hero: 'Réinitialisation du mot de passe',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br><br>" +
      "<strong>Vous n'avez pas reçu cet e-mail ?</strong><br> Vérifiez vos courriers indésirables ou contactez:<br>",
  },
  requests: {
    title: 'Demandes',
    empty_title: 'Aucune demande en cours',
    empty_description: 'Avez-vous une demande à soumettre ?',
    new: '+ Nouvelle demande',
    new_title: 'Nouvelle demande',
    update_title: 'Modifier la demande',
    send: 'Envoyer ma demande',
    compteur: 'Compteur',
    admin: 'Mon conseiller',
    compteurs: 'Compteur(s)',
    compteur_number: 'Numéro du compteur',
    type: 'Type de demande',
    message: 'Message',
    messages: 'Messages',
    object: 'Objet',
    max_object_length: '80 caractères maximum',
    max_message_length: '5000 caractères maximum',
    message_placeholder: 'Taper votre message ici...',
    max_files: 'Importer un fichier (3 max)',
    creation_date: 'Date de création',
    created:
      'Votre demande a bien été créée. Si vous avez des filtres actifs, vous ne la verrez peut-être pas dans la liste.',
    pdl: 'PDL',
    pdl_detail: 'Voir détail',
    category: 'Catégorie',
    not_read: 'Non lu',
  },
  request_filter: {
    title: 'Filtres',
    status: 'Statuts',
    type: 'Type de demande',
    search: 'Taper votre recherche ici...',
  },
  select: {
    all: 'Tout sélectionner',
    none: 'Tout désélectionner',
  },
  file: {
    default_label: 'Fichiers',
    delete: 'Supprimer le fichier {{file}}',
    default_drop_zone_label: 'Glissez vos fichiers ici',
    browse: 'Parcourir',
    max_size: 'Max',
    max_files_other: '{{count}} fichiers maximum',
    max_files_one: 'Un fichier maximum',
    max_files_zero: 'Aucun fichier restant',
    max_error_files: 'Vous ne pouvez pas envoyer plus de {{maxFiles}} fichiers',
    max_error_size_one:
      "Un fichier dépasse 10Mo, vous pouvez l'envoyer par email à :\n" +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    max_error_size_other:
      'Certains fichiers dépassent 10Mo, vous pouvez envoyer l’envoyer par email à :\n' +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link> avec la référence de la demande',
    named_max_error_size_one: 'Le fichier: {{name}}, dépasse 10Mo',
    named_max_error_size_other: 'Les fichiers: {{name}}, dépassent 10Mo',
  },
  profil: {
    title: 'Informations personnelles',
    identity: 'Identité',
    firstname: 'Prénom',
    lastname: 'Nom',
    email: 'E-mail',
    update_password: 'Modifier mot de passe',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe*',
    confirmPassword: 'Confirmer le nouveau mot de passe*',
    validate: 'Valider mot de passe',
    cancel: 'Annuler',
    change_password_success: 'Votre mot de passe a bien été modifié',
  },
  compteurs: {
    title: 'Compteurs',
    AccounttName: 'Raison sociale',
    Name: 'Nom du compteur',
    PRM_RAE_PDL__c: 'PDL',
    ShippingStreet: 'Adresse',
    ShippingPostalCode: 'Code postal',
    ShippingCity: 'Ville',
    Segment__c: 'Segment',
    Etat_contractuel__c: 'Statut réseau',
    active: 'Actif',
    inactive: 'Inactif',
    total: 'Nombre total: {{total}}',
    empty_title: 'Aucun compteur n’est lié à votre compte',
    empty_description:
      'Si besoin, veuillez contacter le service client: ' +
      '<Link href="mailto:serviceclient.energie@hellio.com">serviceclient.energie@hellio.com</Link>',
  },
  compteur_details: {
    title: 'Compteur {{id}}',
    name: 'Compteur',
    active: 'Actif',
    address: 'Adresse',
    Segment__c: 'Segment',
    Formule_Tarifaire_d_Acheminement_TURPE__c:
      'Formule Tarifaire d’Acheminement (TURPE)',
    Consommation_Annuelle_de_Reference_en_MW__c:
      'Consommation annuelle de réf en MWh',
    Type_de_compteur__c: 'Type de compteur',
    Etat_contractuel__c: 'Etat contractuel',
    Puissance_de_raccordement_en_KVA__c: 'Puissance de raccordement en KVA',
    HPTE: 'Heures de pointe',
    HPSH: 'Heures Pleines Saison Haute',
    HCSH: 'Heures Creuses Saison Haute',
    HPSB: 'Heures Pleines Saison Basse ',
    HCSB: 'Heures Creuses Saison Basse',
    puissance: 'Puissance',
    consommation_annuelle: 'Consommation annuelle',
  },
  factures: {
    title: 'Factures',
    pageTitle: 'Mes factures',
    searchLabel: 'De quel(s) compteur(s) ?',
    searchPlaceholder: 'Sélectionner un compteur',
    searchButton: 'Rechercher',
    noBillsMessage:
      'Aucune facture n’est encore disponible sur votre espace client.',
    mailContactMessage: 'Si besoin, veuillez contacter le service client :',
    noPdlSelectedLabel: "Aucun compteur n'a été sélectionné",
    noPdlSelectedMessage: 'Veuillez sélectionner un ou plusieurs compteur(s)',
    montantTotalLabel: 'Montant total facturé',
    tableVue: 'Vue tableau',
    barVue: 'Vue graphique',
    raisonSociale: 'Raison Sociale',
    pdl: 'PDL',
    reference: 'Ref. facture',
    billDate: 'Date Facture',
    address: 'Adresse',
    postalCode: 'Code postal',
    city: 'Ville',
    amount: 'Montant total TTC',
    download: 'Télécharger',
    searchSelectedFactures:
      'Les <1>{{count}} factures</1> de la recherche sont sélectionnées.',
    pageSelectedFactures:
      'Les <1>{{count}} factures</1> de cette page sont sélectionnées.',
    availableSelectedFactures:
      'Sélectionner les {{count}} factures disponibles.',
    eraseSelection: 'Effacer la sélection',
  },
  contact: {
    title: 'Contact',
    subtitle: 'Assistance et accompagnement',
    message:
      'Une question sur votre facture ? Besoin d’un conseil sur nos offres ?\nNous sommes disponibles pour répondre à vos questions.',
    mail_action: 'Contacter par email',
    phone_action: 'Contacter par téléphone',
    request_title: 'Mes demandes',
    request_action: 'Envoyer une demande',
  },
  consommation: {
    title: 'Mes consommations',
    not_dev_title:
      'Notre équipe travaille activement sur cette nouvelle fonctionnalité',
    not_dev_subtitle:
      'Elle sera bientôt disponible. Nous vous tiendrons informé(é).',
  },
};
